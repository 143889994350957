import router from "./router";
import store from "@/store/index"
//  挂载路由导航守卫
const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  console.log(11,to.path,22,to.fullPath)
  const hasToken =store.state.logins.vuexToken|| localStorage.getItem("token");
  if (hasToken) {
        next()
  } else {
    // 没有token，强制跳转到登录页
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`);
    }
  }
});
