import axios from "axios";
import { Loading, Message } from "element-ui";
import cache from '@/plugins/cache'
let loading = null;
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from "@/utils/ruoyi";
import { saveAs } from 'file-saver'
let downloadLoadingInstance;
export function showLoading(msg = "加载中") {
  // loading = Loading.service({
  //   lock: true,
  //   text: msg,
  //   background: "rgba(0, 0, 0, 0.7)",
  // });
}

export function showSuccess(msg = "成功") {
  // loading.close();
  Message.close();
  // Message({
  //   message: msg,
  //   duration: 500,
  //   type: "success",
  // });
}

export function showFail(msg = "失败") {
  // loading.close();
  Message.close();
  Message({
    message: msg,
    duration: 2000,
    type: "warning",
  });
}

// 第一种封装请求的方式

const request = axios.create({
  timeout: 1 * 60 * 60 * 1000,
  // baseURL: "https://retail.api.haixiangjicai.com/api",
  // baseURL: "http://192.168.5.101:8083",
    baseURL: "/api", //跨域时候
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // showLoading();
    
    // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  
 if( !config.headers.operate){
  config.headers.operate ='ROLE_SUPPLIER'
 }
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (localStorage.getItem("token") && !isToken) {
    config.headers['Authorization'] = localStorage.getItem("token") // 让每个请求携带自定义token 请根据实际情况自行修改
  }
 
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        Message({
          message: message,
          duration: 2000,
          type: "warning",
        });
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
    return config;
  },
  (err) => {
    Message({
      message: '请求错误',
      duration: 2000,
      type: "error",
    });
  }
);

// 响应拦截器
request.interceptors.response.use(
  (config) => {
    showSuccess();
    return config.data;
  },
  (err) => {
    // showFail();
    Message({
      message:'登录失败',
      duration: 2000,
      type: "warning",
    });
    if (err.code === "401") {
      // 执行401
      console.log("request文件",401)
    } else if (err.code === "404") {
      // 404
      console.log("request文件",404)
    } else {
      console.log("request文件",err)
      return Promise.reject(err);
    }
  }
);
// 通用下载方法
export function  download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return request.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}
export default request;
