import Vue from 'vue'
import Vuex from 'vuex'

import logins from '@/store/modules/logins'
import home from '@/store/modules/home'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
	logins,
	home,
  }
})

export default store
