import request from "./request";

//  post
export function getLogin(data,params) {
  return request({
    url: "/api/login",
    method: "post",
    headers:{
        'login-type':'PW',
    },
    data: data, // 这个是放在body
    params: params, // 这个是放在params  就是url地址后面的参数
  });
}

// post
// export function getvvhan(data, params, ids) {
//   return request({
//     url: "https://api.vvhan.com/api/qqsc/" + ids,
//     method: "post",
//     data: data, // 这个是放在body
//     params: params, // 这个是放在params  就是url地址后面的参数
//   });
// }

// // put
// export function getvvhan(data) {
//   return request({
//     url: "https://api.vvhan.com/api/qqsc",
//     method: "put",
//     data: data, // 这个是放在body
//   });
// }

// // delete
// export function getvvhan(data) {
//   return request({
//     url: "https://api.vvhan.com/api/qqsc",
//     method: "delete",
//     data: data, // 这个是放在body
//   });
// }
