import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
// 获取原型对象上的push函数
const originalPush = Router.prototype.push;
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// const routes = [
//   {
//     path: "/login",
//     name: "login",
//     meta: { title: "login" },
//     component: () => import("@/views/login/index.vue"),
//   },
//   {
//     path: "/",
//     name: "home",
//     redirect: "/organization",
//     meta: { title: "首页" },
//     component: () => import("@/views/home/index.vue"),
//     children: [
//       {
//         path: "/organization",
//         name: "organization",
//         meta: { title: "机构管理" },
//         component: () => import("@/views/organization/index.vue"),
//       },
//     ],
//   },
//   {
//     path: "/404",
//     name: "notFound",
//     component: () => import("@/views/NotFound/404.vue"),
//     meta: { title: "错误", hidden: true },
//   },
// ];
// const router = new Router({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// export default router;

export const constantRoutes = [
  {
    path: "/login",
    name: "login",
    meta: { title: "登录",icon:"el-icon-s-promotion" },
    component: () => import("@/views/login/index"),
  },
  {
    path:"",
    redirect: "/index",
    meta: { title: "布局页" ,icon:"el-icon-s-promotion" },
    component:()=>import("@/views/home/index"),
    children: [
      {
        path: "/index",
        name: "index",
        component:()=>import("@/views/index"),
        meta: { title: "工作台",icon:"el-icon-s-promotion",hidden:true },
      },
      {
          path: "/shopListIndex",
          name: "shopListIndex",
          component:()=>import("@/views/shopList/index"),
          meta: { title: "商品管理",icon:"el-icon-s-promotion" },
          children:[
                {
                  path: "/shopTotal",
                  name: "shopTotal",
                  meta: { title: "待提货商品统计" },
                  component:()=>import("@/views/shopList/shopTotal"),
                },
                {
                  path: "/shopTodayTotal",
                  name: "shopTodayTotal",
                  meta: { title: "今日商品实时销售统计" ,hidden:true},
                  component:()=>import("@/views/shopList/shopTodayTotal"),
                },
                {
                  path: "/shopList",
                  name: "shopList",
                  meta: { title: "商品列表" },
                  component:()=>import("@/views/shopList/shopList"),
                },
                {
                  path: "/shopNo",
                  name: "shopNo",
                  meta: { title: "库存预警" },
                  component:()=>import("@/views/shopList/shopNo"),
                },
              ]
      },
      {
          path: "/orderIndex",
          name: "orderIndex",
          meta: { title: "采购订单" ,icon:"el-icon-s-promotion"},
          component:()=>import("@/views/order/index"),
          children:[
            {
              path: "/orderList",
              name: "orderList",
              meta: { title: "订单列表" },
              component: () => import("@/views/order/orderList"),
            },
            {
              path: "/orderPickUp",
              name: "orderPickUp",
              meta: { title: "待提货列表" },
              component: () => import("@/views/order/orderPickUp"),
            },
            {
              path: "/orderNo",
              name: "orderNo",
              meta: { title: "缺货订单记录" },
              component: () => import("@/views/order/orderNo"),
            },
          ]
      },
      {
          path: "/clearOrderIndex",
          name: "clearOrderIndex",
          meta: { title: "结算中心" ,icon:"el-icon-s-promotion"},
          component: () => import("@/views/clearOrder/index"),
          children:[
            {
              path: "/clearUp",
              name: "clearUp",
              meta: { title: "待结算采购订单" },
              component: () => import("@/views/clearOrder/clearUp"),
            },
            {
              path: "/recound",
              name: "recound",
              meta: { title: "结算记录" },
              component: () => import("@/views/clearOrder/recound"),
            },
            {
              path: "/recoundDetail",
              name: "recoundDetail",
              meta: { title: "结算记录",hidden:true },
              component: () => import("@/views/clearOrder/recoundDetail"),
            },
            // {
            //   path: "/clearUser",
            //   name: "clearUser",
            //   meta: { title: "结算账户" },
            //   component: () => import("@/views/clearOrder/clearUser"),
            // },
          ]
        },
    ],
  },
  {
    path: "*",
    name: "notFound",
    component: () => import("@/views/NotFound/404"),
    meta: { title: "错误", hidden: true },
  },
];

const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; 
}

export default router;
