import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './icons'
import "./permission.js";
//引入下载
import {download} from '@/api/request'
// 引入PC端 element-ui 组件库
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { size: "mini" });
Vue.prototype.download = download
// 引入移动端 Vant 组件库
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
//引入md5加密
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

Vue.config.productionTip = false;

Vue.prototype.$showMsg = function () {

  
  console.log("showMsg");
};


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
