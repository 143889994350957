
const home = {
namespaced: true,
  state: {
	
  },
  mutations: {
    
	
  },

  actions: {
    
  }
}

export default home
