import {getLogin} from '@/api/login'
import {  Message } from "element-ui";
const logins = {
	namespaced: true,
	state: {
		vuexToken:''
	},
	mutations: {
		GETLOGIN(state,res){
		state.vuexToken=res
		localStorage.setItem("token", 'hx-token'+res)
		}
	},

	actions: {
		getUserLogin({commit}, Data) {
			return new Promise((resolve, reject) => {
				getLogin(Data).then(res => {
					if(res.code==200){
					console.log(res)
					commit("GETLOGIN",res.token)
					resolve(res)
					}else {
						Message({
							message: '账号或密码错误',
							duration: 2000,
							type: "error",
						  });
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		
	}
}

export default logins