<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
//开启这个，页面只要卸载，立即执行
//  mounted () {
//     // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
//     window.onbeforeunload = (e)=> {
//       const storage = window.localStorage
//       storage.clear()
//     }
//   },

  methods: {},
};
</script>

<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
  height: 100vh;
}
div{
  box-sizing: border-box;
}
</style>
